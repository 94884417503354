import { types, flow, cast, getParent, getRoot } from "mobx-state-tree";
import BillingsModel from "./BillingsModel";
import FileDownload from "js-file-download";
import FileSaver from "file-saver";
import axios from "axios";
import moment from "moment";

export default types
  .model("TagesabrechnungState", {
    state: types.optional(types.array(BillingsModel), []),
    billing: types.optional(types.maybeNull(BillingsModel), {}),
    updatedAt: types.optional(types.string, ""),
    loading_weekly: types.optional(types.boolean, true),
    loading_localUnit: types.optional(types.boolean, true),
    loading_businessUnit: types.optional(types.boolean, true),
    loading_businessUnit_input: types.optional(types.boolean, false),
    selectedBusinessUnit: types.optional(types.number, -1),
    currentDate: types.optional(types.string, moment().format("DD.MM.YYYY")),
    processingQuery: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get billingsAccount_data() {
      return this.state
        .map((e) => ({
          key: e.BusinessUnit.id,
          dataIndex: "total",
          title: e.BusinessUnit.name,
        }))
        .concat({
          key: "-1",
          title: "",
        });
    },

    toLocaleString(value) {
      return parseFloat(value).toLocaleString("de-CH");
    },

    get grandTotalPaymentAccounts_amount() {
      return self.grandTotalPaymentAccountCash + self.grandTotalPaymentAccountCards + self.grandTotalPaymentAccountVoucher;
    },

    get accountsComparisonValue() {
      return self.grandTotalSaleAccounts_amount - self.grandTotalPaymentAccounts_amount;
    },

    get grandTotalSaleAccounts_amount() {
      let amount = 0;
      self.state.map((state) => {
        state.sales_accounts.map((saleAccount) => {
          saleAccount.Values.map((value) => {
            amount = amount + value.amount;
          });
        });
      });

      return amount;
    },

    get grandTotalSaleAccounts_qty() {
      let amount = 0;
      self.state.map((state) => {
        state.sales_accounts.map((saleAccount) => {
          saleAccount.Values.map((value) => {
            amount = amount + value.qty;
          });
        });
      });

      return amount;
    },

    get grandTotalPaymentAccountCash() {
      let amount = 0;
      self.state.map((state) => {
        state.cash.map((saleAccount) => {
          saleAccount.Values.map((value) => {
            amount = amount + value.amount;
          });
        });
      });

      return amount;
    },

    get grandTotalPaymentAccountCards() {
      let amount = 0;
      self.state.map((state) => {
        state.cards.map((saleAccount) => {
          saleAccount.Values.map((value) => {
            amount = amount + value.amount;
          });
        });
      });

      return amount;
    },
    get grandTotalPaymentAccountVoucher() {
      let amount = 0;
      self.state.map((state) => {
        state.vouchersInvoice.map((saleAccount) => {
          saleAccount.Values.map((value) => {
            amount = amount + value.amount;
          });
        });
      });

      return amount;
    },
    get business_unit_filters() {
      const temp = [];
      this.state
        .map((e) => ({ text: e.BusinessUnit.name, value: e.BusinessUnit.id }))
        .sort((a, b) => a - b)
        .forEach((e) => {
          if (temp.length !== 0) {
            if (!temp.find((emp) => emp.value === e.value)) temp.push(e);
          } else {
            temp.push(e);
          }
        });

      return temp;
    },

    get userType() {
      let user_type = "none";
      if (this.state.length === 0) {
        return user_type;
      } else {
        this.state.forEach((e) => {
          if (e.user_type_id) user_type = e.UserType.name;
        });
      }

      return user_type;
    },
    get allComments() {
      const commentArrau = [
        ...this.state.map((billing) => {
          return billing.Comments;
        }),
      ];
      const allComments = [].concat.apply([], commentArrau);

      return allComments;
    },

    get userType() {
      return !!self.billing.UserType ? self.billing.UserType.name : "";
    },

    get comparison() {
      const [umsatzkonten, zahlungskonten] = ["umsatzkonten", "zahlungskonten"].map((account) =>
        self.billing[account].length !== 0 ? self.billing[account].reduce((a, b) => (a = parseFloat(a || 0) + ((b.id < 0 && b.amount) || 0)), 0) : 0
      );

      return umsatzkonten - zahlungskonten;
    },

    get areAccountsEmpty() {
      return ["umsatzkonten", "zahlungskonten", "eihnnamen"].every((e) => self.billing[e].length === 0);
    },

    fillMissingValues(Account, types, isCalculation) {
      const columns = self.billing.Columns.filter((e) => types.includes(e.type)).sort((a, b) => {
        if (self.typePriority(a.type) < self.typePriority(b.type)) return -1;
        if (self.typePriority(a.type) > self.typePriority(b.type)) return 1;

        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
      });

      const getCalculationGesamt = (e) => {
        if (isCalculation) {
          const gesamt = e.Values.find((v) => v.billing_account_columns_id !== 1) || { amount: 0, qty: 0 };

          return gesamt;
        }

        return {};
      };

      return Account.map((e) =>
        !e.isStatic || (e.isStatic && !e.isTitle)
          ? {
              ...e,
              ...getCalculationGesamt(e),
              Values: columns
                .map((column) => ({
                  ...(e.Values.find((value) => value.billing_account_columns_id === column.id) || {
                    billing_account_columns_id: column.id,
                    amount: 0,
                    qty: 0,
                  }),
                  type: column.type,
                }))
                .sort((a, b) => {
                  if (self.typePriority(a.type) < self.typePriority(b.type)) return -1;
                  if (self.typePriority(a.type) > self.typePriority(b.type)) return 1;

                  if (a.billingAccount_columns_id < b.billingAccount_columns_id) return -1;
                  if (a.billingAccount_columns_id > b.billingAccount_columns_id) return 1;
                }),
            }
          : e
      );
    },

    typePriority(type) {
      return ["personal", "employee", "takeaway"].includes(type.toLowerCase()) ? 1 : 2;
    },

    sortValues(Accounts) {
      return Accounts.map((e) => {
        const Values = [...e.Values]; // Make new copy of Values (remove reference)
        let additional = {},
          index = -1;

        if ((index = Values.findIndex((value) => value.billing_account_columns_id === 1)) > -1) {
          const grand_total = Values.splice(index, 1)[0];
          additional = {
            qty: (grand_total.qty || 0).toLocaleString("de-CH"),
            amount: (grand_total.amount || 0).toLocaleString("de-CH"),
          };
        }
        const sorted_values = Values.sort((a, b) => a.billing_account_columns_id - b.billing_account_columns_id).map((s) => ({
          ...s,
          qty: (s.qty || 0).toLocaleString("de-CH"),
          amount: (s.amount || 0).toLocaleString("de-CH"),
        }));

        return {
          ...e,
          Values: sorted_values,
          ...additional,
        };
      });
    },
  }))
  .actions((self) => ({
    SET(property, value) {
      self[property] = value;
    },

    FETCH_DATA: flow(function* (date, id, isLocalUnit = false) {
      // self.billing = {};

      self[isLocalUnit ? "loading_localUnit" : "loading_businessUnit"] = true;
      if (date !== "") {
        const { data } = yield axios.get(`/api/billings/${isLocalUnit ? "all/" : ""}${id}/${date}`);
        //console.log(data, "datadata");
        let _data = {};

        if (data.hybrid) {
          data.data.Columns = data._columns;
          _data = {
            ...data.data,
          };

          self.billing = _data;
        } else {
          self.billing = data;
        }

        self.currentDate = date;
      }
      self[isLocalUnit ? "loading_localUnit" : "loading_businessUnit"] = false;
      // console.log(data);
    }),

    FETCH_DATA_WEEKLY: flow(function* (date, business_units, language, localUnitID) {
      self.loading_weekly = true;

      if (business_units.length) {
        const { data } = yield axios.get(`/api/billings/weekly/${date}/[${business_units}]`, {
          params: {
            language,
            localUnitID,
          },
        });

        self.billing = cast(data);
      }

      self.loading_weekly = false;
    }),
    GET_PDF: flow(function* (weeklyViewData) {
      try {
        let params = {
          umsatzkonten: weeklyViewData.umsatzkonten,
          calculationWeekly: weeklyViewData.calculationWeekly,
          zahlungskonten: weeklyViewData.zahlungskonten,
          einnahmen: weeklyViewData.einnahmen,
          incomeAndExpenseWeekly: weeklyViewData.incomeAndExpenseWeekly,
          dataSourceComments: weeklyViewData.Comments,
          buNameList: weeklyViewData.buNameList,
          buName: weeklyViewData.buName,
          date: weeklyViewData.date,
        };
        // console.log(params, "PDF");
        let response = yield axios.post(`/api/download/tagesabrechnung/businessUnits/weekly/pdf_export`, { params }, { responseType: "blob" });
        //let response = yield axios.post(`/api/download/tagesabrechnung/businessUnits/weekly/pdf_export`, { params });
        //console.log(response, "response");
        return [response, null];
      } catch (error) {
        return [null, error];
      }
    }),
    EXPORT_DATA_LOCAL_UNIT_WEEKLY: flow(function* (date, business_units) {
      const { data } = yield axios.post(`/api/download/tagesabrechnung/localUnits/weekly/${date}`, { business_units });

      const _weeklyData = data.map((_d) => [
        _d["Blg"],
        _d["Datum"],
        _d["Kto"],
        _d["S/H"],
        _d["Grp"],
        _d["Gkto"],
        _d["SId"],
        _d["SIdx"],
        _d["KIdx"],
        _d["BTyp"],
        _d["MTyp"],
        _d["Code"],
        _d["Netto"],
        _d["Steuer"],
        _d["FW-Betrag"],
        _d["Tx1"],
        _d["Tx2"],
        _d["PkKey"],
        _d["OpId"],
        _d["Flag"],
      ]);

      let _csvContent = "";

      const _header = [
        "Blg",
        "Datum",
        "Kto",
        "S/H",
        "Grp",
        "Gkto",
        "SId",
        "SIdx",
        "KIdx",
        "BTyp",
        "MTyp",
        "Code",
        "Netto",
        "Steuer",
        "FW-Betrag",
        "Tx1",
        "Tx2",
        "PkKey",
        "OpId",
        "Flag",
      ].join(",");

      _csvContent += _header + "\r\n";

      for (const _wD of _weeklyData) {
        let row = _wD.join(",");
        _csvContent += row + "\r\n";
      }
      const BOM = "\uFEFF";
      _csvContent = BOM + _csvContent;
      const _blob = new Blob([_csvContent], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(_blob, `Tagesabrechnung_weekly - ${date}.csv`);
    }),

    EXPORT_DATA_LOCAL_UNIT: flow(function* (date, local_unit_name, local_unit_id) {
      const groups = getParent(self)
        .groups.state.toJSON()
        .sort((a, b) => a.order_no - b.order_no)
        .map((e) => e.name);
      const businessUnits = getRoot(self)
        .businessUnits.state.toJSON()
        .filter((e) => e.local_unit_id === local_unit_id);
      const b_len = businessUnits.length;

      const payloads = {
        data: {
          ...self.billing,
          comparison: getRoot(self).billings.comparison,
          incomeAndExpenseRow: self.billing.incomeAndExpenseRow,
          calculations: self.billing.calculations().map((e) => {
            const { id, name, key, isPercent, ...rest } = e;
            const values = Object.values(rest);
            const missing_values = b_len - values.length;
            const insert = missing_values > 0 ? new Array(missing_values).fill(isPercent ? "0%" : 0) : [];

            return {
              name: name,
              values: values.concat(insert),
            };
          }),
          BusinessUnits: businessUnits.map((e) => ({ ...e, business_unit_id: e.id, business_unit_name: e.name })),
        },
        groups,
        local_unit_name,
      };

      const { data } = yield axios.post(`/api/download/tagesabrechnung/localUnits/${date}`, {
        ...payloads,
      });
      // Opens new tab to preview
      window.open(data, "_blank");
    }),

    EXPORT_DATA_BUSINESS_UNIT: flow(function* (fileType, date, selectedBusinessUnit) {
      const businessunit = getRoot(self)
        .businessUnits.state.toJSON()
        .find((e) => e.id == self.selectedBusinessUnit).name;

      const options =
        fileType === "csv"
          ? [
              {
                data: {
                  BillingAccounts: self.billing.BillingAccounts.map((e) => {
                    const value = (id) => e.Values.find((value) => value.billing_account_columns_id === id) || { qty: 0, amount: 0 };

                    return {
                      id: e.id,
                      account: e.Account.name,
                      ...self.billing.Columns.reduce(
                        (a, b) => (a = { ...a, [`${b.name}-qty`]: value(b.id).qty, [`${b.name}-amount`]: value(b.id).amount }),
                        {}
                      ),
                    };
                  }),
                },
              },
              { responseType: "blob" },
            ]
          : [
              {
                data: {
                  umsatzkonten: self.fillMissingValues(self.sortValues(self.billing.sales(selectedBusinessUnit)), ["employee", "personal", "takeaway"]),
                  zahlungskonten: self.fillMissingValues(self.billing.payments(selectedBusinessUnit), ["employee", "personal", "takeaway", "gerate"]),
                  // zahlungskonten2: self.billing.payments(selectedBusinessUnit, self.billing.sales(selectedBusinessUnit, true)),
                  eihnnamen: self.fillMissingValues(self.sortValues(self.billing.BillingAccounts.filter((e) => e.Account.account_type_id === 3)), ["employee"]),
                  calculations: self.fillMissingValues(self.billing.calculations(true), ["employee", "personal", "takeaway"], true),
                  Columns: self.billing.Columns.sort((a, b) => {
                    if (self.typePriority(a.type) < self.typePriority(b.type)) return -1;
                    if (self.typePriority(a.type) > self.typePriority(b.type)) return 1;

                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                  }),
                  EmployeeColumns: self.billing.Columns.filter((e) => e.type === "employee").sort((a, b) => a.id - b.id),
                  Comments: self.billing.Comments,
                },
                business_unit_name: businessunit,
              },
            ];

      // console.log(self.billing.calculations(true));

      const { data } = yield axios.post(`/api/download/tagesabrechnung/businessUnits/${fileType}/${date}`, ...options);
      if (fileType === "pdf") {
        // Opens new tab to preview
        window.open(data, "_blank");
      }
      if (fileType === "csv") {
        // console.log(self.billing.business_unit_name);
        FileDownload(data, `Business Unit_${self.billing.business_unit_name} - ${date}.csv`);
      }
    }),

    FETCH_DATA_LOCAL_UNIT: flow(function* (local_unit_id = "", date = moment(Date.now() - 8 * 24 * 60 * 60 * 1000).format("DD.MM.YYYY")) {
      self.loading = true;

      const { data } = yield axios.get(`/api/billings/all/${local_unit_id}/${date}`);
      self.state = cast(data);

      self.loading = false;
    }),
    //
    LOADING_INPUT: flow(function* (value) {
      self.loading_businessUnit_input = value;
    }),
    ADD: flow(function* (date, business_unit_id, type, values) {
      if (self.loading_businessUnit_input === false) {
        self.loading_businessUnit_input = true;
        self.processingQuery = true;
        const { data } = yield axios.post(`/api/billings/${self.currentDate}/${business_unit_id}/${type}`, values);
        if (type == "comments") {
          values.id
            ? self.billing.Comments.splice(
                self.billing.Comments.findIndex((e) => e.id === values.id),
                1,
                data
              )
            : self.billing.Comments.push(data);
        }
        //console.log(self.loading_businessUnit_input, "check1");
        if (type == "values") {
          //self.loading_businessUnit_input = true;

          const { Account, OrderDetails, Values, additionalInfo, ...rest } = data;

          const property = values.isStatic ? "BillingStaticAccounts" : "BillingAccounts";
          const BillingAccount = self.billing[property].find((e) => e.id === data.billing_account_id) || { Values: [] };

          if (!!BillingAccount.Values && BillingAccount.Values.length !== 0) {
            const index = BillingAccount.Values.findIndex((e) => e.id === data.id);

            if (index > -1) {
              BillingAccount.Values.splice(
                BillingAccount.Values.findIndex(
                  (e) => e.billing_account_id === data.billing_account_id && e.billing_account_columns_id === data.billing_account_columns_id
                ),
                1,
                rest
              );
            } else {
              BillingAccount.Values.push(rest);
            }
          } else {
            if (!!BillingAccount.id) {
              BillingAccount.Values.push(rest);
            } else {
              // console.log(Account);
              // console.log(OrderDetails);
              self.billing[property].push({
                ...(property === "BillingAccounts" ? { Account, OrderDetails } : {}),
                id: data.billing_account_id,
                account_id: values.account_id,
                billing_id: data.billing_id,
                isStatic: additionalInfo?.isStatic,
                name: additionalInfo?.name,
                isChecked: 0,
                Values: cast([rest]),
              });
            }

            if (self.billing.id === 0) {
              self.billing.SET("id", data.billing_id);
              self.billing.SET("business_unit_id", data.business_unit_id);
              self.billing.SET("status", 1);
            }
          }
          //self.loading_businessUnit_input = false;
          //console.log(self.loading_businessUnit_input, "check2");
        }

        if (type == "account") {
          if (!!values.id) {
            self.billing.BillingAccounts.splice(
              self.billing.BillingAccounts.findIndex((data) => data.id === values.id),
              1,
              data
            );
          } else {
            self.billing.BillingAccounts.push(data);
          }
        }

        if (type == "columns") {
          // If values is array, meaning number of columns is changes, else, the value of a column is changed
          if (Array.isArray(values)) {
            self.billing.SET("Columns", data);
          } else {
            self.billing.SET_COLUMNS(data[0]);
          }
        }
        self.loading_businessUnit_input = false;
        self.processingQuery = false;
      }
    }),

    UPDATE_RELEASE: flow(function* (date, selectedBusinessUnit, values) {
      const { data } = yield axios.put(`/api/billings/release/${selectedBusinessUnit}/${date}`, values);
      self.billing = data;
    }),
  }));
